import React from "react";

const LINKEDIN = () => (
  <svg
    width="41"
    height="41"
    viewBox="0 0 40 40"
    className="img-bounce"
  >
    <path
      d="M0 2.827C0 1.266 1.297 0 2.898 0h33.416c1.6 0 2.898 1.266 2.898 2.827v33.807c0 1.561-1.297 2.827-2.898 2.827H2.898C1.298 39.46 0 38.195 0 36.634z"
      fill="#069"
    />
    <path
      d="M11.886 33.033V15.214H5.964v17.819zM8.925 12.78c2.065 0 3.35-1.368 3.35-3.078-.038-1.749-1.285-3.079-3.31-3.079-2.027 0-3.351 1.33-3.351 3.079 0 1.71 1.285 3.078 3.272 3.078h.039zM15.164 33.033h5.922v-9.951c0-.533.039-1.065.195-1.445.428-1.064 1.403-2.166 3.039-2.166 2.143 0 3 1.634 3 4.03v9.532h5.922V22.816c0-5.473-2.922-8.02-6.818-8.02-3.195 0-4.598 1.786-5.377 3.002h.04v-2.584h-5.923c.077 1.672 0 17.819 0 17.819z"
      fill="#fff"
      fillRule="evenodd"
    />
  </svg>
);

export default LINKEDIN;
