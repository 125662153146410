import React from "react";

const GITHUB = ({
  fill,
  circleFill,
  className
}) => (
  <svg  
    height="41"
    width="41"
    viewBox="0 0 16.399 16.399"
    className={className}
  >
    <ellipse fill={circleFill} cx="8.186" cy="8.216" rx="8.128" ry="8.136"/><path d="M3.43 9.848c-.041-.042-.041-.084 0-.126a.34.34 0 0 1 .314-.095c.126.022.2.042.22.064.147.062.31.203.489.424.177.22.318.371.424.455.503.42 1.017.452 1.54.096a.942.942 0 0 1 .204-.379.951.951 0 0 1 .269-.219c.083-.042.23-.105.44-.19-.692-.062-1.253-.183-1.683-.361-.43-.177-.77-.403-1.022-.676-.335-.377-.54-.87-.613-1.477a3.785 3.785 0 0 1 .141-1.667c.126-.315.304-.598.534-.85-.167-.523-.135-1.12.096-1.792a3.262 3.262 0 0 1 1.823.693c1.048-.273 2.138-.284 3.27-.032.147-.105.372-.236.676-.393.305-.157.687-.246 1.148-.268.084.232.141.504.173.817a2.66 2.66 0 0 1-.048.881c.482.503.734 1.174.754 2.013 0 .67-.113 1.226-.345 1.665-.23.441-.64.808-1.225 1.103-.4.188-.923.303-1.573.345.293.147.509.3.644.455.137.158.225.404.267.74v.99l.033.959a1.11 1.11 0 0 0 .219.268 1.102 1.102 0 0 0 .22.156c.064.032.084.079.064.142-.022.063-.106.095-.252.095-.357 0-.64-.126-.85-.377a.72.72 0 0 1-.094-.378v-1.51c0-.167-.042-.287-.126-.361a.613.613 0 0 0-.251-.142v1.982c0 .356.042.587.126.691.083.105.136.21.158.314.02.022.005.037-.048.048a.61.61 0 0 1-.267-.048c-.272-.062-.462-.203-.566-.424a1.625 1.625 0 0 1-.157-.707v-1.95h-.41v1.95c0 .252-.051.491-.155.723-.148.293-.42.45-.819.472-.063-.022-.094-.042-.094-.064.021-.02.084-.126.189-.314a1.827 1.827 0 0 0 .126-.691v-1.982a.51.51 0 0 0-.236.142c-.075.074-.11.194-.11.361v1.51a.72.72 0 0 1-.095.378c-.188.251-.472.377-.848.377-.148 0-.232-.032-.252-.095-.022-.042-.017-.079.016-.11a.957.957 0 0 1 .14-.11.938.938 0 0 0 .127-.094.573.573 0 0 0 .188-.251c.063-.084.079-.305.048-.662-.033-.355-.037-.587-.017-.69-.544.188-1.1.094-1.665-.284-.168-.167-.336-.408-.504-.723-.125-.23-.377-.504-.754-.817Z" fill={fill}
    stroke={fill}
    strokeWidth=".069"/><path d="M12.256 7.638s.84-.093 2.008.114m-1.935-.327s.85-.16 2.013-.044m-10.116.437s-.839-.094-2.007.114m1.93-.327s-.849-.16-2.012-.045" stroke={fill}
    strokeWidth=".069"/>
  </svg>
);

export default GITHUB;
